
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import EmptyState from '@/components/shared/templates/EmptyState.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    WhiteBlock,
    DetailsHero,
    ChatsSettingsPage,
    InfoCard,
    InfoCardRow,
    EmptyState,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Auto-responders', url: '' },
    ]

    return {
      isEmptyMode,
      breadcrumbs,
    }
  },
})
